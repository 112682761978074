body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: #1c2532;
  color: white;
}

.react-media-vis-body {
  /* height: calc(100vh - var(--react-media-vis-height)); */
  display: flex;
  flex-direction: column;
}

* {
  box-sizing: border-box;
}

html {
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0px;  /* remove scrollbar space */
  background: transparent;  /* optional: just make scrollbar invisible */
}
/* optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #FF0000;
}

